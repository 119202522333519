import React from "react";

const Calendar = props =>{
    const reminders = ["Categorize as SOPI","Adjust Response Options", "Client confirmation email", "Interpreter confirmation email", "SELECT Time. Convert the request time of the call from the client's time zone to AZ time. (Verify time for Daylight Savings"
]

    return (
        <div>
            <h1>Calendar</h1>
            

            {reminders.map((reminder,index)=>{
                return <div className="task">
                    <input type="checkbox" />
                    <p key={index}>{reminder}</p>
                </div>
            })}

            <p> <strong>subject Line:</strong> </p>

            <p>{props.info.clientNumber} {props.info.clientName} {props.info.language} Scheduled OPI {props.subjectDate}, {props.time} {props.info.timeZone} Time</p>
            <p><strong>to:</strong></p>
            <p>cscm@cyracom.com; clientservices@cyracom.com;</p>


            <table>
                <tbody>
                    <tr>
                        <th className="calendar">Client:</th>
                        <td className="calendar">{props.info.clientName}</td>
                    </tr>
                    <tr>
                        <th className="calendar">Acct #:</th>
                        <td className="calendar">{props.info.accountNumber}</td>
                    </tr>

                    
                    <tr>
                        <th className="calendar">Date:</th>
                        <td className="calendar">{props.bodyDate}</td>
                    </tr>
                    <tr>
                        <th className="calendar">Time:</th>
                        <td className="calendar">{props.time} {props.info.timeZone} Time</td>
                    </tr>
                    <tr>
                        <th className="calendar">Requestor Name:</th>
                        <td className="calendar">{props.info.contactFName} {props.info.contactLName}</td>
                    </tr>
                    <tr>
                        <th className="calendar">Requestor: Phone #:</th>
                        <td className="calendar">{props.info.contactPhone}</td>
                    </tr>
                    <tr>
                        <th className="calendar">Email:</th>
                        <td className="calendar">{props.info.contactEmail}</td>
                    </tr>
                    <tr>
                        <th className="calendar">Language:</th>
                        <td className="calendar">{props.info.language}</td>
                    </tr>
                    <tr>
                        <th className="calendar">Interpreter and ID #:</th>
                        <td className="calendar">{props.info.intFName} {props.info.intLName} {props.info.intID}</td>
                    </tr>
                    <tr>
                        <th className="calendar">Phone #:</th>
                        <td className="calendar">{props.info.intPhone}</td>
                    </tr>

                </tbody>
            </table>


        </div>
    )
}

export default Calendar