import React from "react"


const Interpreter = props => {



  const gmt = [-10, -7, -7, -6, -5, -4]
  const timeZones = ["HADT", "AZ Time", "PDT", "MDT", "CDT", "EDT",]
  const subject = `${props.info.clientName} Schedule OPI ${props.info.language} ${props.subjectDate} ${props.time} ${props.info.timeZone} Time`

  const cc = `csrep@cyracom.com`


  return (
    <div>
      <h1>interpreter Confirmation</h1>

      

      <a href={`mailto:${props.info.intMail}?cc=cscm@cyracom.com;csrep@cyracom.com&subject=${subject}`}>Interpreter Confirmation</a>

      <div className="task">
        <input type="checkbox" />
        <p>Add interpreter reply</p>
      </div>

      <div className="task">
        <input type="checkbox" />
        <p>add reminder</p>
      </div>

      <img className = "headerImage" src="images/header.png" alt="Cyracom Header" />
      <p>Dear {props.info.intFName},</p>

      <p>This is to confirm your scheduled telephonic interpretation for <strong>{props.info.language}</strong> on <strong>{props.bodyDate}</strong>  at <strong>{props.time + " " + props.info.timeZone} time </strong> for <strong>{props.info.duration}</strong>.
      </p>





      <table>
        <thead>
          <tr className="row">

            {timeZones.map(zone => <th
              key={zone}
              className="purple"
            >{zone}</th>)}

          </tr>

        </thead>
        <tbody>
          <tr>
            {gmt.map((offset, index) => {

              let hourDiff = props.greenwichTime + offset
              let mins = props.minutes + props.am

              if(Number(hourDiff)==0){
                hourDiff = 12
              }else if (Number(hourDiff) < 0) {
                hourDiff = 12 + hourDiff
                mins = props.minutes + " AM"
              }

              return <td key={index}> {hourDiff + mins}</td>
            })}
          </tr>
        </tbody>

      </table>

      <h2 className="h2Gold">Next Steps</h2>


      <p>I’d like to take a moment to go over a few reminders:</p>
      <ul>
        <li>Please remember to log out and be available offline 5 minutes before the scheduled appointment.
        </li>

        <li> It is very important that you be completely available for this call near the appointment time.
        </li>

        <li>Client Services will call you when the client is on the line.</li>

        <li>Always confirm the client name before accepting call.  This will ensure that only the scheduled call is connected to you.</li>

        <li>  If you do not receive the call within the 15 minute scheduled time, please log back in.</li>

      </ul>

      <p></p>
      <p className="nextToHightlightet">If you have any questions please contact</p>
      <p className="gold"> Client Services at 1-800-481-3289.</p>
    </div>

  )
}

export default Interpreter