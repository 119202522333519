import React from "react";

const Request = props=>{
    const date = new Date()

    const currentTime = date.getHours()

    let greeting = ""

    currentTime>11?greeting = `Good afternoon ${props.info.intFName},`: greeting = `Good morning ${props.info.intFName},`

    const subject = `Availability Request ${props.info.language} ${props.subjectDate} ${props.time} ${props.info.timeZone} Time`


    return(
        <div>
            <h1>Availability Request</h1>
            <a href={`mailto:?cc=csrep@cyracom.com&bcc=_${props.info.language}&subject=${subject}`}>Availability Request</a>

            <p>{greeting}</p>
            
            <p>We have a request for a <strong>{props.info.language} Scheduled OPI</strong> on <strong>{props.bodyDate}</strong> at <strong>{props.time} {props.info.timeZone} Time</strong> for <strong>{props.info.duration}</strong>.</p>
            <p>Please <strong>"REPLY ALL"</strong> to advice your availability.</p>
        </div>
    )
}

export default Request