import React from "react";

const Client = props => {
    return (
        <div>

            <h1>Client Confirmation</h1>
            <a href={`mailto:${props.info.contactEmail}?from=clientservices@cyracom.com&cc=csrep@cyracom.com; cscm@cyracom.com; ${props.info.amEmail}&subject=${props.info.clientName} ${props.info.clientNumber}  Schedule OPI ${props.info.language} ${props.subjectDate} ${props.time} ${props.info.timeZone} Time`}>Client</a>

            <div className="task">
                <input type="checkbox" />
                <p>send from client services</p>
            </div>

            <img className = "headerImage" src="images/header.png" alt="Cyracom Header" />

            <p>Dear {props.info.contactFName},</p>
            <p>This is to confirm your scheduled telephonic interpretation for the below appointment in {props.info.timeZone} Time</p>

            <table>
                <thead>
                    <tr>
                        <th className="clientFacing">Service Date/Time</th>
                        <th className="clientFacing">Duration</th>
                        <th className="clientFacing">Language</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td className="clientFacing">{props.bodyDate} {props.time}</td>
                        <td className="clientFacing">{props.info.duration}</td>
                        <td className="clientFacing">{props.info.language}</td>

                    </tr>
                </tbody>

            </table>

            <p>Interpreter information for your call: Name: {props.info.intFName}  {props.info.intID}.  Note: Reserved interpreter may be subject to change.</p>

            <h3>Next Steps</h3>

            <p>Here are a few reminders:</p>

            <p><strong>Over-the-Phone</strong> It is required that you call <strong>866-998-0309</strong> and enter your account/PIN at the scheduled start time. Your call will be directed to a unique line for speedy connection to your reserved interpreter. Failing to call the toll-free number above may result in delays in connecting to your reserved interpreter. <strong>Please note the use of this toll-free number is for confirmed pre-scheduled appointments only.</strong></p>

            <p><strong>CyraCom Mobile Application</strong> Select 'SOPI' as the language. <strong>Please note that selecting SOPI is for confirmed pre-scheduled appointments only.</strong></p>

            <p>If you are running late for an appointment, we kindly ask that you notify us as soon as possible so that we can advise the interpreter.</p>
            <p>In the event of a cancellation, please notify us immediately so that we can inform the interpreter.</p>
            <p>If no call is received within 15 minutes after the appointment start time to alert of a delay or cancellation, the reserved interpreter will be released to assist other clients.</p>
            <p>If you have any questions please contact Client Services at 1-800-481-3289.</p>
        </div>
    )
}

export default Client