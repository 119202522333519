import React, { useState } from "react"
import Signature from "./Signature"
import Interpreter from "./Interpreter"
import Client from "./Client"
import Calendar from "./Calendar"
import Request from "./Request"
import Unsuccesful from "./Usuccesful"

const date= new Date()
//getMonth() starts at 0 for jan therefore we add 1
let month = date.getMonth()+1
let day = date.getDate()+2

if (String(month).length<2){month = "0"+String(month)}
if (String(day).length<2){day = "0"+String(day)}


const today = date.getFullYear() +"-"+ month +"-"+ day


function App() {
  const [info, setInfo] = useState({
    intFName: "",
    intLName: "",
    intMail: "",
    intID: "",
    intPhone: "",
    clientName: "",
    clientNumber: "",
    accountNumber: "",
    contactEmail: "",
    contactPhone: "",
    contactFName: "",
    contactLName:"",
    amEmail: "",
    date: `${today}T00:00`,
    language: "",
    timeZone: "",
    duration: "",
  })








  const CC = info.amEmail + "csrep@cyracom.com, cscm@cyracom.com"

  const bodyDate = info.date.substr(5, 2) + "/" + info.date.substr(8, 2) + "/" + info.date.substr(0, 4)

  const subjectDate = info.date.substr(5, 2) + "." + info.date.substr(8, 2) + "." + info.date.substr(0, 4)

  const subject = info.clientName + " Schedule OPI " + " " + info.language + " " + subjectDate + " " + info.time

  const clientSubject = info.clientName + " " + info.clientNumber + " Schedule OPI " + " " + info.language + " " + subjectDate + " " + info.time


  const handleChange = event => {
    const name = event.target.name
    let value = event.target.value


    if (name === "timeZone") {
      let gmt = 0
      switch (value) {
        case "Hawaii": gmt = -10
          break
        case "Arizona": gmt = -7
          break
        case "Pacific": gmt = -7
          break
        case "Central": gmt = -5
          break
        case "Eastern": gmt = -4
          break
        case "Mountain": gmt = -6
          break
      }


      setInfo(prev => {
        return { ...prev, timeZone: value, gmt: gmt }
      })

    } else {
      setInfo(prev => {
        return { ...prev, [name]: value }
      })
    }
  }


  let am = " AM"
  let time = ""
  let greenwichTime = 0
  let minutes = info.date.substr(13, 3)
  let hour = info.date.substr(11, 2)





  const getTime = () => {

    if (info.date.substr(11, 2) > 11) { am = " PM" }
    if (hour > 12) { hour = hour - 12 }
    if (hour[0] == 0) { hour = info.date.substr(12, 1) }

    time = hour + minutes + am
    greenwichTime = hour - info.gmt
  }




  getTime()



  return (
    <div className="App">

      <form >
        <div>
          {/* <h1>{month}</h1> */}
          <h3>interpreter</h3>

          <input
            placeholder="Interpreter Email"
            type="email"
            name="intMail"
            value={info.intMail}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Interpreter First Name"
            type="text"
            name="intFName"
            value={info.intFName}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Interpreter Last Name"
            type="text"
            name="intLName"
            value={info.intLName}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Interpreter ID"
            type="text"
            name="intID"
            value={info.intID}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Interpreter Phone"
            type="text"
            name="intPhone"
            value={info.intPhone}
            onChange={event => handleChange(event)}
          />
        </div>



        <div>
          <h3>client</h3>
          <input
            placeholder="Contact First Name"
            type="email"
            name="contactFName"
            value={info.contactFName}
            onChange={event => handleChange(event)}
          /> 

          <input
            placeholder="Contact Last Name"
            type="email"
            name="contactLName"
            value={info.contactLName}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Contact Email"
            type="email"
            name="contactEmail"
            value={info.contactEmail}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Contact Phone"
            type="phone"
            name="contactPhone"
            value={info.contactPhone}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Account Number"
            type="text"
            name="accountNumber"
            value={info.accountNumber}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Client Name"
            type="text"
            name="clientName"
            value={info.clientName}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Client Number"
            type="text"
            name="clientNumber"
            value={info.clientNumber}
            onChange={event => handleChange(event)}
          />

        </div>

        <div>
          <h3>SOPI</h3>
          <input
            placeholder="Account Manager"
            type="email"
            name="amEmail"
            value={info.amEmail}
            onChange={event => handleChange(event)}
          />

          <input
            type="datetime-local"
            name="date"
            value={info.date}
            onChange={event => handleChange(event)}
          />

          <input
            placeholder="Language"
            type="Text"
            name="language"
            value={info.language}
            onChange={event => handleChange(event)}
          />


          <input
            placeholder="Duration"
            type="text"
            name="duration"
            value={info.duration}
            onChange={event => handleChange(event)}
          />

          <p>Time Zone</p>

          <div className="timeZones">
            <div>
              <input
                onChange={event => handleChange(event)}
                type="radio"
                id="H"
                name="timeZone"
                value="Hawaii" />
              <p>Hawaii</p>
            </div>
            <div>
              <input
                onChange={event => handleChange(event)}
                type="radio"
                id="A"
                name="timeZone"
                value="Arizona" />
              <p>Arizona</p>
            </div>
            <div>
              <input
                onChange={event => handleChange(event)}
                type="radio"
                id="P"
                name="timeZone"
                value="Pacific" />
              <p>Pacific</p>
            </div>
            <div>
              <input
                onChange={event => handleChange(event)}
                type="radio"
                id="M"
                name="timeZone"
                value="Mountain" />
              <p>Mountain</p>
            </div>
            <div>
              <input
                onChange={event => handleChange(event)}
                type="radio"
                id="C"
                name="timeZone"
                value="Central" />
              <p>Central</p>
            </div>
            <div>
              <input
                onChange={event => handleChange(event)}
                type="radio"
                id="Eastern"
                name="timeZone"
                value="Eastern" />
              <p>Eastern</p>
            </div>

          </div>


        </div>


      </form>




      <p></p>

      <Request
        info={info}
        subjectDate={subjectDate}
        time= {time}
        bodyDate={bodyDate}
      />
      <Signature />

      <Interpreter
        info={info}
        bodyDate={bodyDate}
        time={time}
        greenwichTime={greenwichTime}
        minutes={minutes}
        am={am}
        subjectDate={subjectDate}
      />
      <Signature />

      <Client
        info={info}
        subjectDate={subjectDate}
        time={time}
        bodyDate={bodyDate}
      />
      <Signature />

      <Calendar
        info={info}
        subjectDate={subjectDate}
        time= {time}
        bodyDate={bodyDate}
      />

      <Unsuccesful 
        info={info}
        subjectDate={subjectDate}
        time={time}
        bodyDate={bodyDate}
      />
      <Signature />

    </div>
  );
}

export default App;
