const Signature = ()=>{
    return <div>
        <p>Thank you,</p>

        <div className="signature">
        <p className="bold">Carlos M Valenzuela</p>
        <p className="italic">Client Services Representative</p>
        <p>Office: (800) 481-3289</p>
        <p>www.cyracominternational.com</p>
        <img className = "footerImage" src="images/footer.png" alt="Footer Image" />
        <p className="disclaimer">DISCLAIMER: This e-mail and any attached content may contain confidential or privileged material delivered for the sole use of the intended recipient(s). Any review, use, distribution or disclosure by others is strictly prohibited. If you are not the intended recipient (or authorized to receive for the recipient), please contact the sender immediately by reply e-mail and delete all copies of this message. It is the recipient's responsibility to scan this e-mail and any attachments for viruses. The content of this e-mail message, including any attachments, does not comprise a contract or a portion of a contract, and so does not bind CyraCom International, Inc. or any of its agents or subsidiaries. CyraCom, LLC and Voiance Language Services, LLC are wholly owned subsidiaries of CyraCom International, Inc.</p>
        </div>
    </div>
}
export default Signature