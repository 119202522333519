import React from "react";

const Unsuccesful = props=>{
    const date = new Date()

    const currentTime = date.getHours()

    let greeting = ""

    currentTime>11?greeting = "Good afternoon": greeting = "Good morning"


    return (
        <div>
            <h1>Unsuccesful</h1>
            <a href={`mailto:${props.info.contactEmail}?from=clientservices@cyracom.com&cc=csrep@cyracom.com; cscm@cyracom.com; ${props.info.amEmail};&subject=${props.info.clientName} ${props.info.clientNumber}  Schedule OPI ${props.info.language} ${props.subjectDate} ${props.time} ${props.info.timeZone} Time`}>Client email</a>

            <p></p>
            <img className = "headerImage" src="images/header.png" alt="Cyracom Header" />


            <p>{greeting} {props.info.contactFName},</p>

            <p>We regret to inform you that we were not able to secure a {props.info.language} interpreter for your requested appointment.</p>

            <table>
                <tbody>
                    <tr>
                        <td className="noBorder">Contact:</td>
                        <td className="noBorder">{props.info.contactFName} {props.info.contactLName}</td>
                    </tr>

                    <tr>
                        <td className="noBorder">Phone #:</td>
                        <td className="noBorder">{props.info.contactPhone}</td>
                    </tr>

                    <tr>
                        <td className="noBorder">e-mail:</td>
                        <td className="noBorder">{props.info.contactEmail}</td>
                    </tr>
                    
   
                    <tr>
                        <td className="noBorder"></td>
                        <td className="noBorder"></td>
                    </tr>

                    <tr>
                        <td className="noBorder">Date of Service:</td>
                        <td className="noBorder">{props.bodyDate}</td>
                    </tr>

                    <tr>
                        <td className="noBorder">Time:</td>
                        <td className="noBorder">{props.time} {props.info.timeZone} Time</td>
                    </tr>

                    <tr>
                        <td className="noBorder"></td>
                        <td className="noBorder"></td>
                    </tr>
                    

                    <tr>
                        <td className="noBorder">Client:</td>
                        <td className="noBorder">{props.info.clientName}</td>
                    </tr>

                    <tr>
                        <td className="noBorder">Account Number:</td>
                        <td className="noBorder">{props.info.accountNumber}</td>
                    </tr>
                    
                </tbody>
            </table>

            <p>If we can further assist you, please, do not hesitate to call.</p>

        </div>
    )
}


export default Unsuccesful